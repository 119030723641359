import React from "react";
import Helmet from "react-helmet";
import "./index.css";

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet
      title="John Ryan"
      htmlAttributes={{ lang: "en" }}
      meta={[
        { name: "description", content: "John Ryan Homepage" },
        { name: "keywords", content: "john, ryan, nyc" }
      ]}
    />
    <link
      href="https://fonts.googleapis.com/css?family=Roboto+Mono:400,700"
      rel="stylesheet"
    />
    <div
      style={{
        margin: "0 auto",
        maxWidth: 960,
        padding: "0px 1.0875rem 1.45rem",
        paddingTop: 0
      }}
    >
      {children}
    </div>
  </div>
);

export default TemplateWrapper;
